import React, { createContext, useState, useEffect } from "react";
import authTokenCheck from "../services/authTokenCheck";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    const userIdFromToken = authTokenCheck();
    setUserId(userIdFromToken);
  }, []);
  const logIn = () => {
    setIsLoggedIn(true);
  };
  const logOut = () => {
    localStorage.removeItem("authToken");
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ userId, isLoggedIn, logOut, logIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
