import React, { useContext, useState } from "react";
import "../styles/stylesAuth.css";
import { registerUser, loginUser } from "../services/api.js"; // Імпортуємо методи
import AuthContext from "../contexts/AuthContext.jsx";
import { toast } from "react-toastify";
const AuthModal = ({ onClose }) => {
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { logIn } = useContext(AuthContext);
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-\=\[\]\{\};:'",<>\./?\\|`~])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-\=\[\]\{\};:'",<>\./?\\|`~]{8,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!passwordRegex.test(password)) {
      setError(
        "Пароль повинен містити мінімум 8 символів, одну цифру та один спеціальний символ."
      );
      return;
    }

    setError("");
    // setSuccessMessage("");

    try {
      if (isRegisterMode) {
        const response = await registerUser({ username, email, password });
        // setSuccessMessage(response.message);
        toast.success("Реєстрація успішна! 🎉");
      } else {
        const token = await loginUser({ email, password });
        toast.success("Логін успішний! 🎉");
        // setSuccessMessage("Логін успішний!");
        localStorage.setItem("authToken", token);
        logIn();
      }

      onClose();
    } catch (err) {
      setError(err.response?.data || "Сталася помилка, спробуйте знову.");
      toast.error("Сталася помилка, спробуйте знову.");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>{isRegisterMode ? "Реєстрація" : "Увійти"}</h2>
        <form onSubmit={handleSubmit}>
          {isRegisterMode && (
            <div className="form-group">
              <label htmlFor="username">Ім'я користувача</label>
              <input
                type="text"
                id="username"
                className="form-control input-wrapper"
                placeholder="Введіть ім'я"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-control input-wrapper"
              placeholder="Введіть email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Пароль</label>
            <input
              type="password"
              id="password"
              className="form-control input-wrapper"
              placeholder="Введіть пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && <div className="error-message">{error}</div>}
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
          </div>
          <button type="submit" className="btn btn-auth btn-success">
            {isRegisterMode ? "Зареєструватись" : "Увійти"}
          </button>
        </form>
        <p className="toggle-auth">
          {isRegisterMode
            ? "Вже є обліковий запис?"
            : "Немає облікового запису?"}{" "}
          <span onClick={() => setIsRegisterMode(!isRegisterMode)}>
            {isRegisterMode ? "Увійти" : "Зареєструватись"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default AuthModal;
