import { jwtDecode } from "jwt-decode";
const authTokenCheck = () => {
  const token = localStorage.getItem("authToken");

  if (token) {
    try {
      // Декодуємо токен
      const decoded = jwtDecode(token);

      const expirationTime = decoded.exp * 1000;
      const currentTime = Date.now();

      if (currentTime > expirationTime) {
        console.log("Токен прострочений");
        localStorage.removeItem("authToken");
        return null;
      } else {
        console.log("Токен ще дійсний");
        const userId =
          decoded[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ];
        console.log("User ID:", userId);
        return userId;
      }
    } catch (error) {
      console.error("Не вдалося декодувати токен", error);
    }
  } else {
    console.log("Токен відсутній");
    return null;
  }
};
export default authTokenCheck;
