import axios from "axios";
const api_url = "https://net-questionsapi.onrender.com/api";
export const getQuestions = async () => {
  try {
    const response = await axios.get(`${api_url}/Question/get_all`);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const getQuestionLinks = async (id) => {
  try {
    const response = await axios.get(
      `${api_url}/Question/get_questionlinks?id=${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const getQuestion = async (id) => {
  try {
    const response = await axios.get(
      `${api_url}/Question/get_question?id=${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching questionInfo:", error);
    throw error;
  }
};
export const getTimecodes = async (id) => {
  try {
    const response = await axios.get(
      `${api_url}/Question/get_timecodes?id=${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const getInterviews = async () => {
  try {
    const response = await axios.get(`${api_url}/Question/get_interviews`);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const registerUser = async (registerData) => {
  try {
    const response = await axios.post(
      `${api_url}/Auth/register`,
      registerData,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      throw new Error(error.response.data.message || "Unknown error");
    } else {
      console.error("Error during registration:", error);
      throw error;
    }
  }
};

export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(`${api_url}/Auth/login`, loginData, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    return response.data.token;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
export const getFavoriteQuestions = async (userId) => {
  try {
    const response = await axios.get(
      `${api_url}/Question/get_favorite_questions?userId=${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching favorite questions:", error);
    throw error;
  }
};

export const addFavoriteQuestion = async (favoriteQuestionDto) => {
  try {
    const response = await axios.post(
      `${api_url}/Question/add_favorite_question`,
      favoriteQuestionDto,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding favorite question:", error);
    throw error;
  }
};
export const removeFavoriteQuestion = async (userId, questionId) => {
  try {
    const response = await axios.delete(
      `${api_url}/Question/remove_favorite_question?userId=${userId}&questionId=${questionId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error removing favorite question:", error);
    throw error;
  }
};
