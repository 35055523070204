import React, { useState } from "react";
import AuthModal from "./AuthModal";

const LoginButton = () => {
  const [showModal, setShowModal] = useState(false);

  const openLoginModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <button className="nav-link" onClick={openLoginModal}>
        <i class="bi bi-person "></i>
        Увійти
      </button>

      {showModal && <AuthModal onClose={closeModal} />}
    </>
  );
};

export default LoginButton;
